_$document.ready(function() {
  // Settings
  var documentHeight = $(document).height();
  var windowHeight = $(window).height();
  var windowWidth = $(window).width();
  var headerHeight = $('.l-header').height();
  var $projectList = $('.c-project-list');
  var $projectHeader = $('.c-project-list__header');
  var projectHeaderHeight = $('.c-project-list__header').height();
  var $projects = $('.c-project-list__item');
  var $projectHeading = $('.c-project-list__heading');


  // Events
  if ( $projects.length ) {
    $(window).on('resize', function() {
      documentHeight = $(document).height();
      windowHeight = $(window).height();
      windowWidth = $(window).width();
      headerHeight = $('.l-header').height();
      projectHeaderHeight = $('.c-project-list__header').height();
    });

    _$document.on('scroll', function() {
      stickHeader();
      scrollProject();
    });
  }

  //functions
  function stickHeader() {
    if ( windowWidth >= 800 ) {
      headerHeight = 0;
    }
    if ( _$document.scrollTop() > ($projectList.offset().top - headerHeight) ) {
      $projectHeader.addClass('is-sticky');
    } else {
      $projectHeader.removeClass('is-sticky');
    }
  }

  function changeProject(id) {
    $projectHeading.removeClass('is-visible');
    $projectHeading.filter('[data-for="' + id + '"]').addClass('is-visible');
  }

  function scrollProject() {
    $projects.each(function(i) {
      var $project = $(this);
      var id = $project.attr('id');
      var position = $project.offset().top;
      var $nextProject = $($projects[i + 1]);
      var nextPosition = $nextProject|length ? $nextProject.offset().top : documentHeight;
      var transitionPosition = _$document.scrollTop() + windowHeight / 2;

      if ( transitionPosition >= position && transitionPosition < nextPosition ) {
        changeProject(id);
      }
    });
  }
});
