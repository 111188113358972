_$document.ready(function() {
  var $nav = $('.l-nav');

  $('.js-navigation-trigger').on('click', function() {
    if ( $nav.hasClass('is-visible') ) {
      $(this).removeClass('is-opened');
      $('.l-nav').removeClass('is-visible');
    } else {
      $(this).addClass('is-opened');
      $('.l-nav').addClass('is-visible');
    }

  });
});
