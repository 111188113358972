_$document.ready(function() {
  _$document.on('click', '.c-newsletter__title', function() {
    if ( $('.c-newsletter__form').hasClass('is-visible') ) {
      $('.c-newsletter__form').removeClass('is-visible');
    } else {
      $('.c-newsletter__form').addClass('is-visible');
      $('.c-newsletter__form').find('input').first().focus();
      $('html, body').scrollTop(_$document.height());
    }
  });
});
