_$document.ready(function() {
  var slickOptions = {
    fade: true,
    dots: true,
    responsive: [
    {
      breakpoint: 800,
      settings: {
        fade: false,
        dots: false,
        arrows: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
			  focusOnSelect: true,
      }
    }
   ]
 };

  $('.c-gallery__slider').slick(slickOptions);

  _$document.on('click', '.js-gallery-zoom', function() {
    var $thisGallery = $(this).closest('.c-gallery');

    if ( $thisGallery.hasClass('is-zoomed') ) {
      $thisGallery.removeClass('is-zoomed');
      $('html').removeClass('is-locked');
    } else {
      $thisGallery.addClass('is-zoomed');
      $('html').addClass('is-locked');
    }
    $thisGallery.find('.c-gallery__slider').slick('setPosition');
  });
});
